import React from "react"

const StampLogo = ({fillColor}) => {
  return (
    <svg
      width="64"
      height="89"
      viewBox="0 0 64 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.1937 68.2716C42.8545 71.2101 37.8301 72.9054 32.5249 72.9054C22.4761 72.9054 14.2998 66.6611 14.2998 59.004C14.2998 49.9059 20.6349 45.1026 32.5249 45.1026C45.3511 45.1026 50.75 48.2106 50.75 55.6417V62.5359C36.2386 62.4511 31.9632 59.5974 31.9632 50.6688V37.8694C31.9632 28.9408 36.3322 26.0871 50.75 26.0023V31.2295C50.75 40.158 45.9128 43.4356 32.5249 43.4356C20.5101 43.4356 14.2998 38.6323 14.2998 29.5342C14.2998 21.9336 22.4761 15.6328 32.5249 15.6328C37.7365 15.6328 42.8545 17.4128 46.1937 20.3514L47.5044 19.1646C43.7907 15.8871 38.2983 13.9375 32.5249 13.9375C21.5399 13.9375 12.4274 20.9165 12.4274 29.5342C12.4274 39.6494 19.5114 45.1309 32.5249 45.1309C46.8491 45.1309 52.6224 41.1752 52.6224 31.2295V24.307C35.7705 24.2223 30.0907 27.4151 30.0907 37.8694V50.6688C30.0907 61.1231 35.7705 64.3159 52.6224 64.2312V55.6417C52.6224 47.137 46.2873 43.4356 32.5249 43.4356C19.6051 43.4356 12.4274 48.9735 12.4274 59.004C12.4274 67.5935 21.4463 74.6007 32.5249 74.6007C38.2983 74.6007 43.7907 72.7359 47.5044 69.4583L46.1937 68.2716Z"
        fill={fillColor ? fillColor : "#316C5F"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9809 0H49.3991C57.1205 0 63.38 6.17271 63.38 13.7871V75.0633C63.38 82.6777 57.1205 88.8505 49.3991 88.8505H13.9809C6.25945 88.8505 0 82.6777 0 75.0633V13.7871C0 6.17271 6.25945 0 13.9809 0ZM13.9809 1.5319C7.11739 1.5319 1.55343 7.01876 1.55343 13.7871V75.0633C1.55343 81.8317 7.11739 87.3186 13.9809 87.3186H49.3991C56.2626 87.3186 61.8266 81.8317 61.8266 75.0633V13.7871C61.8266 7.01876 56.2626 1.5319 49.3991 1.5319H13.9809Z"
        fill={fillColor ? fillColor : "#316C5F"}
      />
    </svg>
  )
}

export default StampLogo
